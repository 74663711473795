<template>
  <div class="productAudit-container">
    <div class="header_father">
      <div class="header1 list_title">
        <van-nav-bar title="产品提报库" left-text="返回" right-text="提报产品" left-arrow @click-left="onClickLefts" @click-right="newProductDetails"></van-nav-bar>
      </div>
      <div class="texts_cs" v-if="query.flag"></div>
    </div>
    <div class="title_serch">
      <van-search v-model="query.productName" shape="round" show-action background="rgba(0,0,0,0)" placeholder="请输入产品名称">
        <template #action>
          <div @click="onSearch" v-if="btn_status">搜索</div>
          <div v-else>搜索</div>
        </template>
      </van-search>
    </div>
    
      <div class="father_mr1">
        <van-empty image="search" v-if="showEmpty" description="哎呀! 未找到相关记录..." />
        <div class="product_cs">
        </div>
        <div class="animated fadeInUp2">
          <van-list v-model="loading1" :finished="finished" v-if="!(showEmpty)" finished-text="没有更多了" @load="onLoad">
            <div class="commodityList"  v-if="judegList">
              <div class="commodity_cs">
                <van-cell is-link v-for="(item,index) in productList" :key='index' @click="commodityDetails(item)" class="list_cell">
                  <van-card :title="item.productName">
                    <template #title>
                      <div class="van-ellipsis" style="font-weight: 600; font-size: 14px;" v-html="html_decode(item.productName)"></div>
                    </template>

                    <template #thumb>
                      <van-image lazy-load v-if="item.imgs" :src="item.imgs[0]" />
                      <van-image lazy-load v-else :src="require('../../assets/img/img.png')" />
                    </template>
                    <template #tags>
                      <!-- <p class="commodity"><span>产品品牌: </span>{{item.brandName||'-'}}</p> -->
                      <p class="commodity"><span>产品品类: </span>{{item.commodityContractTypeName||'-'}}</p>
                      <p class="commodity"><span>是否外输: </span>{{item.outputName||'-'}}</p>
                    </template>
                  </van-card>
                  <template>
                    <div style="width: 100%; background-color: #fafafa;">
                      <div class="border_cs"></div>
                    </div>

                  </template>
                </van-cell>

              </div>

            </div>

          </van-list>

          <div class="no-data" v-if="!this.productList">
            <img src="../../assets/img/null.gif" alt="暂无记录" class="img" />
          </div>
        </div>

      </div>
    
    <van-tabbar route>
      <van-tabbar-item to="/productAudit" icon="wap-nav">产品提报库</van-tabbar-item>
      <van-tabbar-item to="/brand" icon="card">品牌库</van-tabbar-item>
      <van-tabbar-item to="/product" icon="gift-card">产品库</van-tabbar-item>
      <van-tabbar-item to="/mine" icon="manager">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { Toast } from 'vant';
export default {
  data() {
    return {
      btn_status: true,
      loading1: false,
      showEmpty: false,
      finished: false,
      judegList: true,
      query: {
        flag: true,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        productName: ''
      },
      productList: []
    }
  },
    created() {
    // 控制上方添加圆角样式的显示与隐藏
    this.query.flag = false;
    this.judegList = false;
    setTimeout(()=>{
      this.productLists()
    }, 300)
  },
  methods: {
          // 识别后台传递过来的转义符部分无法识别
      html_decode(str) {
        if(str == undefined || str == ''){
          return '-';
        }else{
          var s = ''
          if (str.length == 0) return ''
          s = str.replace(/&amp;/g, '&')
          s = s.replace(/&lt;/g, '<')
          s = s.replace(/&gt;/g, '>')
          s = s.replace(/&nbsp;/g, ' ')
          s = s.replace(/&#39;/g, "'")
          s = s.replace(/&amp;/g, '&')
          s = s.replace(/&quot;/g, '"')
          s = s.replace(/<br\/>/g, '\n')
          return s
        }
    },
    productLists() {
      let params = {
        page: this.query.pageNum,
        pageSize: this.query.pageSize
      }
      this.$http
        .ajax(
          '/ffkj-main/merchantProduct/list',
          {
            pageNum: this.query.pageNum,
            pageSize: this.query.pageSize,
            merchantId: localStorage.getItem('merchantId'),
            productName: this.query.productName
          },
          'get',
          {}
        )
        .then(res => {
          if (res.code == 0) {
            let rows = res.data.data
            if(res.data.total > 0){
              this.showEmpty = false;
              this.query.flag = true;
            }else{
              this.showEmpty = true;
              this.query.flag = false;
            }
            this.judegList = true;
            this.productList = this.productList.concat(rows);
            this.loading1 = false;
            if (this.productList.length >= res.data.total) {
              // 加载结束
              this.finished = true
              return
            } else {
              this.finished = false
            }
          } else {
            Notify({ type: 'danger', message: res.message })
          }
          if (this.productList == null || this.productList.length === 0) {
            // 加载结束
            this.finished = true
            return
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer)
            }.bind(this),
            3000
          )
        })
    },
    onClickLefts() {
        window.history.go(-1);
    },
    newProductDetails() {
      this.$router.push({
        path: '/applyProductBasic',
        query: {}
      })
    },
    onSearch() {
        Toast.loading({
          message: '玩命加载中...',
          overlay: true,
          duration: 400,
          forbidClick: true,
        });
        this.query.flag = false;
        this.btn_status = false;
        this.productList = [];
        setTimeout(()=>{
          this.query.pageNum = 1
          this.productLists()
        }, 500);
        setTimeout(()=>{
          this.btn_status = true;
        },1500)

    },
    commodityDetails(item) {
      this.$router.push({
        path: '/auditProductDetails',
        query: {
          id: item.id
        }
      })
    },
    //滚动加载时触发，list组件定义的方法
    onLoad() {
      setTimeout(
        function () {
          this.query.pageNum++
          this.productLists()
        }.bind(this),
        3000
      )
    }
  },
}
</script>
<style scoped>
</style>